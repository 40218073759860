'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import WhyRegister from '@edeeone/edee-registration/components/WhyRegister';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { useDropdownContext } from '@edeeone/juan-core/components/dropdown/Dropdown';
import { useMegamenuContext } from '@edeeone/juan-core/components/megamenu/useMegamenu';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getHref } from '@edeeone/string/getHref';
import { useSearchParams } from 'next/navigation';
import { headerLoginClassNames } from './headerLogin.styles';
export const HeaderLogin = ({ styles, showHeading }) => {
    const finalStyles = headerLoginClassNames(styles);
    const { url } = useLocation();
    const searchParams = useSearchParams();
    const variantParam = CONFIG.evita?.staticSearchParameter
        ? {
            [CONFIG.evita.staticSearchParameter]: searchParams.get(CONFIG.evita.staticSearchParameter),
        }
        : {};
    const { t } = useI18n('HeaderLogin');
    const { close } = useMegamenuContext();
    const { setOpenId } = useDropdownContext();
    return (_jsxs("div", { className: finalStyles.content, children: [showHeading && (_jsx("p", { className: finalStyles.notRegistered, children: t('heading') })), _jsx(Button, { onClick: () => {
                    try {
                        close();
                        setOpenId(undefined);
                    }
                    catch (error) { }
                    window.history.pushState({}, '', getHref(url, { ...variantParam, user: 1 }));
                }, children: t('login') }), _jsx("p", { className: finalStyles.notRegistered, children: t('noAccount') }), _jsx(Button, { design: "secondary", onClick: () => {
                    try {
                        close();
                        setOpenId(undefined);
                    }
                    catch (error) { }
                    window.history.pushState({}, '', getHref(url, { ...variantParam, user: 2 }));
                }, children: t('register') }), _jsx(WhyRegister, { design: "secondary" })] }));
};
