'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { MegamenuItem } from '@edeeone/juan-core/components/megamenu/MegamenuItem';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useProductComparison } from '@edeeone/shop-productcomparison/hooks/useProductComparison';
export const MenuComparison = ({ itemDesign = CONFIG?.componentSettings?.shopProductComparison
    ?.MenuProductComparison?.itemDesign ?? 'secondary', itemIcon = CONFIG?.componentSettings?.shopProductComparison
    ?.MenuProductComparison?.itemIcon ?? 'toolChart', productComparisonUrl, }) => {
    const { t } = useI18n('MenuComparison');
    const { isProductComparisonEmpty, productComparisonCount } = useProductComparison();
    return (_jsx(MegamenuItem, { design: itemDesign, data: {
            primaryKey: 'product-comparison',
            name: t('label') || 'compare',
            url: productComparisonUrl,
            icon: itemIcon,
            iconBadge: !isProductComparisonEmpty && productComparisonCount,
        } }));
};
