'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dropdown } from '@edeeone/juan-core/components/dropdown/Dropdown';
import { DropdownItem } from '@edeeone/juan-core/components/dropdown/DropdownItem';
import { Form } from '@edeeone/juan-core/components/form/Form';
import { FormGroup } from '@edeeone/juan-core/components/form/formGroup/FormGroup';
import { SearchInput } from '@edeeone/juan-core/components/form/searchInput/SearchInput';
import { langDropdownClassNames } from '@edeeone/juan-core/components/language/LangDropdown.styles';
import { flagPath } from '@edeeone/juan-core/components/language/Language';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { REGION_SWITCH_PATHNAME } from '@edeeone/juan-core/routes/utils/region';
import { getCountries, getCurrencies, getLanguages, getLocalizationsSetup, } from '@edeeone/juan-core/utils/localization';
import { getHref } from '@edeeone/string/getHref';
import { removeDiacritics } from '@edeeone/string/removeDiacritics';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
const regionList = process.env.JUAN_REGIONS;
const regionSwitchUrl = `/api/region-switch`;
export const LangDropdown = ({ children, showCountryFlags, }) => {
    const pathname = usePathname();
    const { country, locale, currency } = useLocation();
    const finalStyles = langDropdownClassNames();
    const [activeCountry, setActiveCountry] = useState(country);
    const { showCountry, showCurrency, showLang } = getLocalizationsSetup();
    const { t } = useI18n('LangDropdown');
    return (_jsx(_Fragment, { children: _jsx(Dropdown, { heading: t('heading', {
                type: t(`type_${showLang ? 'language' : showCurrency ? 'currency' : 'country'}`),
            }), styles: { container__custom: finalStyles.container }, button: children, children: _jsx(Form, { id: "LangDropdown", defaultValues: {
                    term: '',
                    country,
                    currency,
                    lang: locale,
                    target: pathname,
                }, onSubmit: () => { }, children: ({ getValues, watch }) => {
                    watch(['term', 'country', 'currency', 'lang']);
                    const { country, currency, lang } = getValues();
                    const searchTerm = removeDiacritics(getValues('term'))
                        .trim()
                        .toLocaleLowerCase();
                    const countries = getCountries();
                    const currencies = getCurrencies(country, locale);
                    const languages = getLanguages(country);
                    const showSearch = (countries?.length || 0) +
                        (currencies?.length || 0) +
                        (languages?.length || 0) >
                        10;
                    return (_jsxs(_Fragment, { children: [showSearch && (_jsx(FormGroup, { name: "term", showLabel: false, showOptionalLabelPostfix: false, children: _jsx(SearchInput, {}) })), showCountry &&
                                countries
                                    ?.filter((c) => {
                                    return (searchTerm === '' ||
                                        removeDiacritics(t(`country.${c}`))
                                            .toLocaleLowerCase()
                                            .includes(searchTerm));
                                })
                                    .map((c, i, { length }) => {
                                    const isActive = c === activeCountry;
                                    const languages = regionList.find((r) => {
                                        return r?.countryCode === c;
                                    })?.languages?.[0];
                                    return (_jsx(DropdownItem, { prefetch: false, href: getHref(REGION_SWITCH_PATHNAME, {
                                            region: languages?.region,
                                        }), children: _jsxs("span", { className: twMergeJuan(finalStyles.item, i + 1 === length && finalStyles.item__last), onClick: () => {
                                                setActiveCountry(c);
                                            }, children: [_jsxs("span", { className: finalStyles.radio, children: [_jsx("span", { className: twMergeJuan(finalStyles.radioInput, isActive && finalStyles.radioInput__checked) }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: twMergeJuan(finalStyles.radioIcon, isActive && finalStyles.radioIcon__checked) })] }), showCountryFlags && (_jsx(Image, { src: `${flagPath}${c?.toLowerCase()}.svg`, alt: '', width: 15, height: 15, className: finalStyles.icon })), _jsx("span", { className: twMergeJuan(finalStyles.label, isActive && finalStyles.label__checked), children: t(`country.${c}`) })] }) }, `region-${i}`));
                                }), showCurrency &&
                                currencies
                                    ?.filter((c) => {
                                    return (searchTerm === '' ||
                                        removeDiacritics(c?.currency)
                                            .toLocaleLowerCase()
                                            .includes(searchTerm) ||
                                        removeDiacritics(t(`currency.${c?.currency?.toLowerCase()}.symbol`))
                                            .toLocaleLowerCase()
                                            .includes(searchTerm));
                                })
                                    ?.map((c, i, { length }) => {
                                    const isActive = c?.currency?.toLowerCase() === currency;
                                    return (_jsx(DropdownItem, { prefetch: false, href: getHref(REGION_SWITCH_PATHNAME, {
                                            region: c?.region,
                                        }), children: _jsxs("span", { className: twMergeJuan(finalStyles.item, i + 1 === length && finalStyles.item__last), children: [_jsxs("span", { className: finalStyles.radio, children: [_jsx("span", { className: twMergeJuan(finalStyles.radioInput, isActive && finalStyles.radioInput__checked) }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: twMergeJuan(finalStyles.radioIcon, isActive && finalStyles.radioIcon__checked) })] }), _jsxs("span", { className: twMergeJuan(finalStyles.label, isActive && finalStyles.label__checked), children: [c?.currency, " (", _jsx("span", { dangerouslySetInnerHTML: {
                                                                __html: t(`currency.${c?.currency?.toLowerCase()}.symbol`) || 'currency',
                                                            } }), ")"] })] }) }, `currency-${i}`));
                                }), showLang &&
                                languages
                                    ?.filter((c) => {
                                    return (searchTerm === '' ||
                                        removeDiacritics(process.env.JUAN_LANGUAGE?.[c?.lang])
                                            .toLocaleLowerCase()
                                            .includes(searchTerm));
                                })
                                    ?.map((c, i, { length }) => {
                                    const isActive = c?.lang === lang;
                                    return (_jsx(DropdownItem, { prefetch: false, href: getHref(regionSwitchUrl, { region: c?.region }), children: _jsxs("span", { className: twMergeJuan(finalStyles.item, i + 1 === length && finalStyles.item__last), children: [_jsxs("span", { className: finalStyles.radio, children: [_jsx("span", { className: twMergeJuan(finalStyles.radioInput, isActive && finalStyles.radioInput__checked) }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: twMergeJuan(finalStyles.radioIcon, isActive && finalStyles.radioIcon__checked) })] }), _jsx("span", { className: twMergeJuan(finalStyles.label, isActive && finalStyles.label__checked), children: process.env.JUAN_LANGUAGE?.[c?.lang] || c?.lang })] }) }, `language-${i}`));
                                })] }));
                } }) }) }));
};
