import { jsx as _jsx } from "react/jsx-runtime";
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useMemo } from 'react';
import { availabilityClassNames } from './Availability.styles';
import { getAvailabilityTotalCount, } from '@edeeone/juan-core/components/availability/availability.utils';
export const Availability = function ({ styles, design, size, stocks, availability, className, i18nKey, }) {
    const colorI18nKey = `color.${availability}`;
    const textI18nKey = `text.${availability}`;
    const { t } = useI18n(`${i18nKey ? `${i18nKey}.` : ''}availability`);
    const finalStyles = availabilityClassNames(styles, design, size, {});
    const totalCount = useMemo(() => {
        return getAvailabilityTotalCount(stocks);
    }, [stocks]);
    if (!availability || !stocks) {
        return null;
    }
    if (t(textI18nKey, { count: totalCount })?.startsWith('???')) {
        return null;
    }
    return (_jsx("p", { className: twMergeJuan(finalStyles.root, finalStyles.root__custom, className, `${t(colorI18nKey, { count: totalCount })}`), children: t(textI18nKey, { count: totalCount }) }));
};
