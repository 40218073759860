'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import Link from 'next/link';
import { DropdownButtonWrapper } from '../dropdown/DropdownButtonWrapper';
import { Tab } from './Tab';
import { tabsClassNames } from './tabs.styles';
import { useState } from 'react';
export const Tabs = function ({ blocks, active, styles, isFullWidth = true, isTabFullWidth = true, noWrap = false, showBorder = true, design = 'primary', itemsAlign = 'center', showDropdownOnSmallDevice, showDropdown = false, dropdownOpenerInfo, dropdownTitle, allowOverflow = false, allowFadingBox = false, shallow, }) {
    // this is used when showDropdownOnSmallDevice || showDropdown is true. For eliminating INP metric core web vitals
    const [lastClicked, setLastClicked] = useState(null);
    const finalStyles = tabsClassNames(styles, design, undefined, {
        showDropdownOnSmallDevice,
        showDropdown,
        isFullWidth,
        isTabFullWidth,
        noWrap,
        showBorder,
    });
    const activeTab = blocks?.find((_, idx) => {
        return idx == active;
    });
    return (_jsxs("div", { className: twMergeJuan(finalStyles.container, showBorder && finalStyles.container__border, finalStyles.container__custom, allowFadingBox && finalStyles.container__fadingBox), children: [_jsx("ul", { className: twMergeJuan(finalStyles.ul, isFullWidth && finalStyles.ul__full, itemsAlign === 'center' && finalStyles.ul__center, itemsAlign === 'left' && finalStyles.ul__left, itemsAlign === 'right' && finalStyles.ul__right, allowOverflow && finalStyles.ul__overflow), children: blocks?.map(({ name, icon, url }, idx) => {
                    const isActive = active == idx;
                    return (_jsx("li", { className: twMergeJuan(finalStyles.li, isActive && finalStyles.li__current, isTabFullWidth && finalStyles.li__full, noWrap && finalStyles.li__noWrap), children: _jsx(Tab, { href: url, active: isActive, icon: icon, design: design, shallow: shallow, children: name }) }, idx));
                }) }), (showDropdownOnSmallDevice || showDropdown) && (_jsx("div", { className: finalStyles.dropdown, children: _jsx(DropdownButtonWrapper, { showIconLeft: true, asDropdown: showDropdown, dropdownTitle: dropdownTitle, opener: _jsxs("span", { className: finalStyles.dropdown_opener, children: [dropdownOpenerInfo && (_jsxs("span", { className: finalStyles.dropdown_opener_info, children: [dropdownOpenerInfo, ":", ' '] })), activeTab?.name] }), children: _jsx("ul", { className: finalStyles.dropdown_ul, children: blocks?.map(({ name, icon, url }, idx) => {
                            const isActive = lastClicked != null ? lastClicked == idx : active == idx;
                            return (_jsx("li", { className: finalStyles.dropdown_li, children: _jsxs(Link, { href: url || '', className: twMergeJuan(finalStyles.dropdown_a, isActive && finalStyles.dropdown_a_active), scroll: false, prefetch: false, onClick: () => {
                                        setLastClicked(idx);
                                    }, children: [_jsxs("span", { className: finalStyles.dropdown_radio, children: [_jsx("span", { className: twMergeJuan(finalStyles.dropdown_radioInput, isActive && finalStyles.dropdown_radioInput__checked) }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: twMergeJuan(finalStyles.dropdown_radioIcon, isActive && finalStyles.dropdown_radioIcon__checked) })] }), icon && (_jsx(SvgSpriteIcon, { icon: icon, className: finalStyles.dropdown_a_icon })), _jsx("span", { className: finalStyles.dropdown_label, children: name })] }) }, `${idx}${url}`));
                        }) }) }) }))] }));
};
