'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from '@edeeone/juan-core/components/badge/Badge';
import { Button } from '@edeeone/juan-core/components/button/Button';
import { Dropdown } from '@edeeone/juan-core/components/dropdown/Dropdown';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useWishlist } from '@edeeone/shop-wishlist/hooks/useWishlist';
import { useRouter } from 'next/navigation';
import { headerWishlistClassNames } from './HeaderWishlist.styles';
import { HeaderWishlistItem } from './HeaderWishlistItem';
export const HeaderWishlist = ({ styles = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlist?.styles, showOverlay = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlist
    ?.showOverlay ?? false, showLabel = CONFIG?.componentSettings?.shopWishlist?.HeaderWishlist
    ?.showLabel ?? false, wishlistUrl, }) => {
    const finalStyles = headerWishlistClassNames(styles);
    const { t } = useI18n('HeaderWishlist');
    const { wishlistItems, wishlistCount, isWishlistEmpty } = useWishlist();
    const { push } = useRouter();
    return (_jsx(Dropdown, { hideHeaderBorder: isWishlistEmpty, showOverlay: showOverlay, openOnHover: true, onButtonClick: () => {
            push(wishlistUrl);
        }, button: _jsxs("span", { className: finalStyles.button, children: [_jsxs("span", { className: finalStyles.buttonIcon, children: [_jsx(SvgSpriteIcon, { icon: "headerHeart", className: finalStyles.icon }), !isWishlistEmpty && (_jsx(Badge, { size: "sm", display: "positioned", border: true, children: wishlistCount }))] }), showLabel && (_jsx("span", { className: finalStyles.label, children: t('wishlistLabel') }))] }), buttonLabel: t('wishlistLabel'), heading: isWishlistEmpty ? t('empty') : t('wishlist'), footer: !isWishlistEmpty ? (_jsx(Button, { href: wishlistUrl, shallow: false, design: "primary", nativeHref: true, children: t('show') })) : undefined, children: !isWishlistEmpty && (_jsx("div", { className: finalStyles.container, children: _jsx(ItemList, { data: wishlistItems }) })) }));
};
export const ItemList = ({ data, styles }) => {
    const finalStyles = headerWishlistClassNames(styles);
    return (_jsx("ul", { className: finalStyles.items, children: data?.map((product) => {
            return (_jsx(HeaderWishlistItem, { productId: product.primaryKey, name: product?.attributes?.name || '', url: product?.attributes?.url || '', prices: { priceForSale: product.priceForSale }, motive: product?.attributes?.motive }, product?.primaryKey));
        }) }));
};
