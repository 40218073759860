'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useField } from '@edeeone/juan-core/components/form/useField';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { SvgSpriteIcon } from '../../svgSpriteIcon/SvgSpriteIcon';
import { radioGroupClassNames } from './radioGroup.styles';
export const RadioGroup = ({ styles, design = 'primary', items = [], hideValidationMessage = false, ...props }) => {
    const finalStyles = radioGroupClassNames(styles, design);
    const { t } = useI18n('Form');
    const { value: fieldValue, id, onBlur, onChange, error, name, label, } = useField(props);
    const hasError = !!error;
    return (_jsxs("div", { className: twMergeJuan(finalStyles.group, finalStyles.group__custom, hasError && finalStyles.group__error), children: [label && (_jsx("label", { className: twMergeJuan(finalStyles.groupLabel, finalStyles.groupLabel__custom, hasError && finalStyles.label__error), children: label })), items.map(({ label: itemLabel, value: itemValue, itemLabelPrefix, itemLabelSuffix, }) => {
                return (_jsxs("div", { className: twMergeJuan(finalStyles.container, finalStyles.container__custom), children: [_jsxs("div", { className: finalStyles.content, children: [_jsx("input", { type: "radio", name: name, checked: fieldValue === itemValue, value: itemValue, id: `${id}-${itemValue}`, className: twMergeJuan(finalStyles.input, finalStyles.input__custom), onChange: onChange, onBlur: onBlur }), _jsx(SvgSpriteIcon, { icon: "formCheck", ariaHidden: true, className: finalStyles.icon })] }), _jsxs("label", { className: twMergeJuan(finalStyles.label, itemValue === fieldValue && finalStyles.label__checked), htmlFor: `${id}-${itemValue}`, children: [itemLabelPrefix && (_jsx("span", { className: finalStyles.labelPrefix, children: itemLabelPrefix })), _jsx("span", { children: itemLabel }), itemLabelSuffix && (_jsx("span", { className: finalStyles.labelSuffix, children: itemLabelSuffix }))] })] }, itemValue));
            }), !hideValidationMessage && error && (_jsx("p", { className: finalStyles.error, children: t(error, {
                    field: _jsx("strong", { className: finalStyles.errorField, children: label }),
                }) }))] }));
};
