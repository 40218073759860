'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@edeeone/juan-core/components/button/Button';
import { Input } from '@edeeone/juan-core/components/form/input/Input';
import { searchInputClassNames } from '@edeeone/juan-core/components/form/searchInput/searchInput.styles';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useFormContext } from 'react-hook-form';
export const SearchInput = ({ name, searchStyles, disableRemove = false, clearButtonDesign = 'tertiary', clearButtonIcon = 'cross', clearButtonIconSize = '10', onClearInput: onClearInputProp, design = 'quaternary', ...props }) => {
    const { t } = useI18n('SearchInput');
    const finalStyles = searchInputClassNames(searchStyles);
    const { setValue, watch } = useFormContext();
    const hasValue = !!name ? watch(name) : props.value || props.defaultValue;
    const onClearInput = () => {
        if (!!name) {
            setValue(name, '', {
                shouldDirty: true,
                shouldTouch: true,
            });
        }
        onClearInputProp?.();
    };
    return (_jsx(Input, { ...props, design: design, name: name, beforeInput: _jsx(SvgSpriteIcon, { className: finalStyles.icon, icon: "magnifier", "aria-hidden": true }), afterInput: hasValue &&
            !disableRemove && (_jsx(Button, { title: t('delete'), design: clearButtonDesign, icon: clearButtonIcon, iconSize: clearButtonIconSize, onClick: onClearInput })) }));
};
