export const getOrderabilityForAvailability = (availability) => {
    const orderability = (CONFIG?.shop?.product?.availabilities ?? []).find((a) => {
        return a?.code === availability;
    })?.orderability || 'ALWAYS';
    return orderability;
};
export const getAvailabilityTotalCount = (stocks) => {
    return (stocks?.reduce((acc, stock) => {
        return acc + Number(stock?.attributes?.quantityOnStock || 0);
    }, 0) || 0);
};
