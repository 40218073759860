'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { useChangePathname } from '@edeeone/juan-core/hooks/useChangePathname';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getActiveWeb } from '@edeeone/juan-core/routes/utils/getActiveWeb';
import { getDeviceType } from '@edeeone/juan-core/utils/getDeviceType';
import { useTrackingRecentlyVisitedQuery } from '@edeeone/shop-tracking/graphql/tracking.codegen';
import { getHref } from '@edeeone/string/getHref';
import { useRouter } from 'next/navigation';
import { createContext, useContext, useEffect, useRef, useState, } from 'react';
import { ResultSectionEnum, getSearchHistory, searchHistoryKey, } from './WhispererTypes';
import { useWhispererAllQuery } from './whisperer.codegen';
export const pushSearchHistory = (newValue) => {
    const nextVal = newValue?.trim();
    if (typeof window !== 'undefined') {
        const searchHistoryStorage = window?.localStorage?.getItem(searchHistoryKey);
        const currentSearch = searchHistoryStorage
            ? JSON?.parse(searchHistoryStorage)
            : [];
        window?.localStorage?.setItem(searchHistoryKey, JSON.stringify([
            ...(nextVal?.length > 0 && !currentSearch?.includes(nextVal)
                ? [nextVal]
                : []),
            ...(currentSearch || []),
        ]));
    }
};
const WhispererContext = createContext(undefined);
export const useWhispererInputRegister = () => {
    const { setSearchQuery, inPortal, setOpened, setFocused } = useWhispererStore();
    const inputElement = useRef(null);
    // autofocus na mobilech nefunguje, proto je třeba toto řešit takto.
    useEffect(() => {
        if (inPortal && inputElement.current) {
            inputElement.current.focus();
        }
    }, []);
    return {
        ref: inputElement,
        autoComplete: 'off',
        autoFocus: false,
        onKeyUpCapture: (event) => {
            // force close on escape
            if (event.key === 'Escape') {
                setOpened(false);
            }
        },
        onChange: (e) => {
            setSearchQuery(e.target.value);
        },
        onBlur: () => {
            setFocused(false);
        },
        onFocus: () => {
            setFocused(true);
        },
    };
};
const useRegisterWithoutOverlay = (setOpened, opened, handlePathnameChange, inPortal, focused) => {
    const containerRef = useRef(null);
    const onFocus = () => {
        setOpened(true);
    };
    const onBlur = (event) => {
        // Zkontrolujeme, zda event.relatedTarget (element, na který přechází focus)
        // je vnořený v containeru.
        // zároveň při bluru nechceme zavírat na tabletu/mobilu, aby šlo vyvovat zavření klávesnice při scrollu
        const isMobileOrTablet = getDeviceType() !== 'desktop';
        if (containerRef.current &&
            !containerRef.current.contains(event.relatedTarget) &&
            // na androidu se vyvolává nechtěný onBlur a pak pouze problikne
            !inPortal &&
            !isMobileOrTablet) {
            setOpened(false);
        }
    };
    const onClickCapture = (e) => {
        // @ts-ignore
        const closestAElement = e.target.closest('a');
        // pokud se klikne na odkaz, který odkazuje na aktuální stránku, tak se má našeptávač zavřít
        if (closestAElement && closestAElement.href === window.location.href) {
            handlePathnameChange();
        }
    };
    useEffect(() => {
        // timeout tu musí být. Protože komponenta ze které se předává ref a pokud se jedná o modal, tak je renderovaná podmíněnečně
        // kvůli safari je 1ms, jinak by stačila 0
        // na androidu zamezit
        if (!inPortal) {
            setTimeout(() => {
                if (opened) {
                    containerRef.current?.querySelector('input')?.focus();
                }
                else {
                    containerRef.current?.querySelector('input')?.blur();
                }
            }, 1);
        }
    }, [opened, inPortal]);
    // na touch zařízeních je požadavek aby pokud uživatel začne scrollovat, tak aby se skryla klávestice. Na desktopu to není nutné.
    useEffect(() => {
        const touchMoveHandler = (e) => {
            if (focused && opened) {
                containerRef.current?.querySelector('input')?.blur();
            }
        };
        window.addEventListener('touchmove', touchMoveHandler);
        return () => {
            window.removeEventListener('touchmove', touchMoveHandler);
        };
    }, [focused, opened]);
    return {
        ref: containerRef,
        tabIndex: -1,
        onBlur,
        onFocus,
        onClickCapture,
    };
};
export const WhispererStoreClient = ({ children, searchUrl, trackingUrl, ...props }) => {
    const { push } = useRouter();
    const { minCharacters = CONFIG.fulltext.summonFilterMin, orderableOnly = false, smInPortal = false, } = props;
    const { region, webId, params, routeId } = useLocation();
    const routeSearchQueryLikeString = Array.isArray(params?.searchquery)
        ? params?.searchquery[0] || ''
        : params?.searchquery || '';
    const routeSearchQuery = decodeURIComponent(routeSearchQueryLikeString);
    // todo useDeferredValue
    const [searchQuery, setSearchQuery] = useState(routeSearchQuery);
    const [inPortal, setInPortal] = useState(false);
    const [opened, setOpened] = useState(false);
    const [touched, setTouched] = useState(false);
    const [focused, setFocused] = useState(false);
    const [searchHistoryData, _setData] = useState({});
    function setData(newData) {
        _setData((data) => {
            return { ...data, ...newData };
        });
    }
    function setOpenedState(opened, newInPortal) {
        setOpened(opened);
        if (opened) {
            setTouched(true);
        }
        if (!opened) {
            containerRegister.ref.current?.querySelector('input')?.blur();
        }
        if (newInPortal !== undefined || !opened) {
            setInPortal(!!newInPortal);
        }
        // po zavření dialogu smazat query
        if (!opened && inPortal) {
            // ios safari a safari - pokud je na input focus a modal se zavře přes klávesnici např escape klávesou, tak se po zavření modalu zascroluje úplně dolů
            // je třeba před zavřením udělat blur -  https://github.com/algolia/docsearch/issues/1260#issuecomment-1689665918
            containerRegister.ref.current?.querySelector('input');
            setSearchQuery('');
        }
    }
    function clearSearchHistory(e) {
        localStorage.removeItem('searchHistory');
        setData({ searchHistory: [] });
        e.preventDefault();
    }
    const handlePathnameChange = (location) => {
        setOpenedState(false);
        const routeSearchQuery = Array.isArray(location?.params?.searchquery)
            ? location?.params?.searchquery[0] || ''
            : location?.params?.searchquery || '';
        if (location?.routeId === '/edee-fulltext/Search' && !!routeSearchQuery) {
            setSearchQuery(routeSearchQuery);
        }
        else {
            setSearchQuery('');
        }
        // na stránce vyhledávání se má search input předvyplnit vyhledávaným výrazem a nechat vyplněný. Na ostatních stránkách po navigaci input smazat
    };
    const activeWeb = getActiveWeb(webId);
    const canSubmit = !!searchQuery && searchQuery.length >= minCharacters;
    const canFetch = canSubmit && opened;
    const containerRegister = useRegisterWithoutOverlay(setOpenedState, opened, handlePathnameChange, inPortal, focused);
    const { loading: trackingRecentlyLoading, data: recentlyVisited } = useTrackingRecentlyVisitedQuery({
        variables: {
            input: {},
            limit: CONFIG.fulltext.whispererConfig.maxAmountShown.recentlyVisited,
        },
        context: {
            tags: ['recentlyVisited', 'userProfile'],
        },
        skip: !touched,
        fetchPolicy: 'cache-and-network',
    });
    const { loading, data: allQueryData, previousData: allQueryPreviousData, } = useWhispererAllQuery({
        variables: {
            region,
            query: searchQuery,
            sections: activeWeb.fulltext?.sections || [],
            priceList: CONFIG.shop.price.referencePriceListCode,
            orderableOnly,
        },
        skip: !canFetch,
        fetchPolicy: 'cache-and-network',
    });
    // načtení historie vyhledávání
    useEffect(() => {
        if (opened) {
            setData({ searchHistory: getSearchHistory() });
        }
    }, [opened]);
    const data = {
        ...(searchHistoryData || {}),
        ...(allQueryData || allQueryPreviousData || {}),
        recentlyVisited,
    };
    useChangePathname({
        callback: handlePathnameChange,
        includeSearchParams: true,
        changeFull: true,
    });
    //  TODO změnit konfiguraci
    //  doplnit limity vracených dat podle konfigurace, aby se zbytečně nevracelo více, než je potřeba
    const isProductsEnabled = true;
    const productsCount = isProductsEnabled
        ? data?.edeeFulltextProduct?.recordPage?.totalRecordCount || 0
        : 0;
    const categoriesCount = isProductsEnabled
        ? data?.edeeFulltextCategories?.totalRecordCount || 0
        : 0;
    const isBrandsEnabled = true;
    const brandsCount = isBrandsEnabled
        ? data?.edeeFulltextBrands?.totalRecordCount || 0
        : 0;
    const isGroupsEnabled = true;
    const groupsCount = isGroupsEnabled
        ? data?.edeeFulltextGroups?.totalRecordCount || 0
        : 0;
    const stageSectionsCount = (data?.edeeFulltextStageMultiSection?.reduce((runningTotal, section) => {
        return runningTotal + (section?.data?.totalNumberOfRecords || 0);
    }, 0) || 0);
    const whispererHiddenSections = activeWeb.fulltext?.whispererHiddenSections || [];
    const stageSectionsCountWithoutHidden = data?.edeeFulltextStageMultiSection
        ?.filter((section) => {
        return whispererHiddenSections.includes(section?.section);
    })
        .reduce((acc, it) => {
        return acc + (it?.data?.totalNumberOfRecords || 0);
    }, 0) || 0;
    const recentlyVisitedCount = data?.recentlyVisited?.trackingRecentlyVisited?.recordStrip
        ?.totalRecordCount ?? 0;
    const totalCountWithHidden = productsCount +
        categoriesCount +
        brandsCount +
        groupsCount +
        stageSectionsCount;
    // Tato sekce slouží pro navigaci při kliknutí na tlačítko zobrazit všechny výsledky
    // const computedTargetSection: ResultSectionEnum | undefined =
    const computedTargetSection = categoriesCount + productsCount > 0
        ? undefined
        : brandsCount > 0
            ? ResultSectionEnum.brands
            : groupsCount > 0
                ? ResultSectionEnum.groups
                : activeWeb.fulltext?.sections.find((sectionName) => {
                    // Find the first one, that has some length - this is why the order of sections defined in config matters
                    return data?.edeeFulltextStageMultiSection?.find((sectionFromSearch) => {
                        return sectionFromSearch?.section === sectionName;
                    })?.data?.totalNumberOfRecords;
                });
    const searchHistoryCount = (data?.searchHistory?.length ?? 0);
    // příznak jestli má našeptávač prázdný obsah a má se tudíž otevřený jen pokud je focuslý a není zadaný minimální počet znaků
    const hasMinimalCharacters = searchQuery?.length >= minCharacters;
    const hasEmptyContent = searchHistoryCount + recentlyVisitedCount > 0;
    // příznak jestli obsahuje nějaké výsledny
    const hasContent = hasMinimalCharacters && totalCountWithHidden > 0;
    // Při změně orientace okna je třeba našeptávač skrýt
    // nejde použít změnu velikosti okna, protože na androidu to zřejmě vyvová otevření klávestnice
    useEffect(() => {
        const resizeHandler = () => {
            setOpenedState(false);
        };
        setTimeout(() => {
            window.addEventListener('orientationchange', resizeHandler);
        }, 0);
        return () => {
            window.removeEventListener('orientationchange', resizeHandler);
        };
    }, []);
    const finalOpened = (opened && inPortal) ||
        (opened && hasMinimalCharacters) ||
        (opened && hasEmptyContent);
    const linkAll = getHref(`${searchUrl}/${encodeURI(searchQuery)}`, {
        section: computedTargetSection,
    });
    const isSearchHistoryVisible = searchHistoryCount > 0 && !hasMinimalCharacters;
    const isRecentlyVisitedVisible = recentlyVisitedCount > 0 && !hasMinimalCharacters;
    const handleSubmit = (e) => {
        e.preventDefault();
        if (hasMinimalCharacters) {
            // setIsOpen(false);
            pushSearchHistory(searchQuery);
            push(linkAll);
        }
    };
    return (_jsx(WhispererContext.Provider, { value: {
            searchQuery,
            setSearchQuery,
            opened: finalOpened,
            setOpened: setOpenedState,
            focused,
            setFocused: (focused) => {
                setFocused(focused);
                if (focused) {
                    setTouched(true);
                }
            },
            canSubmit,
            clearSearchHistory,
            props: { minCharacters, orderableOnly, smInPortal },
            data,
            productsCount,
            categoriesCount,
            brandsCount,
            groupsCount,
            stageSectionsCount,
            totalCountWithHidden,
            recentlyVisitedCount,
            searchHistoryCount,
            loading: (trackingRecentlyLoading || loading) && opened,
            containerRegister,
            hasMinimalCharacters,
            hasEmptyContent,
            hasContent,
            linkAll,
            searchUrl: searchUrl,
            trackingUrl: trackingUrl,
            inPortal,
            isSearchHistoryVisible,
            isRecentlyVisitedVisible,
            handleSubmit,
            stageSectionsCountWithoutHidden,
        }, children: children }));
};
export const useWhispererStore = () => {
    const context = useContext(WhispererContext);
    if (context === undefined) {
        throw new Error('useWhispererStore must be used within a WhispererProvider');
    }
    return context;
};
