'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMegamenuContext } from '@edeeone/juan-core/components/megamenu/useMegamenu';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { profileMenuClassNames } from '@edeeone/shop-user/components/profile/ProfileMenu.styles';
import Link from 'next/link';
export const MenuItem = ({ icon, linkText, href, badge, children, active = false, // QUESTION: PDU do we use this prop?
design, styles, showActiveLink, currentMenuItemUrl, }) => {
    const finalStyles = profileMenuClassNames(styles, design);
    currentMenuItemUrl = currentMenuItemUrl || '/';
    const { close } = useMegamenuContext();
    const { domain } = useLocation();
    const currentUrl = currentMenuItemUrl.replace(domain, '');
    const isCurrentPage = href.endsWith(currentUrl);
    const enableActive = showActiveLink && isCurrentPage;
    return (_jsxs(Link, { href: href, className: twMergeJuan(finalStyles.link_container, enableActive && finalStyles.link_container__active), onClick: () => {
            close();
        }, children: [_jsx(SvgSpriteIcon, { icon: icon, ariaHidden: true, className: twMergeJuan(finalStyles.link_icon, enableActive && finalStyles.link_icon__active) }), _jsx("span", { className: finalStyles.link_text, children: linkText }), badge && _jsxs("span", { className: finalStyles.link_badge, children: ["\u00A0(", badge, ")"] }), children] }));
};
