'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoginForm } from '@edeeone/edee-registration/components/loginForm/LoginForm';
import { dialogPanelId, Modal, } from '@edeeone/juan-core/components/modal/Modal';
import { Tabs } from '@edeeone/juan-core/components/tabs/Tabs';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { getHref } from '@edeeone/string/getHref';
import dynamic from 'next/dynamic';
import { useRouter, useSearchParams } from 'next/navigation';
import { Suspense, useEffect, useState } from 'react';
import { userModalClassNames } from './UserModal.styles';
import { USER_MODAL_PARAMETER_NAME } from './userUtils';
const RegisterFormSwitch = dynamic(() => {
    return import('./registerForm/RegisterFormSwitch');
}, {
    ssr: false,
});
const PasswordRecoveryForm = dynamic(() => {
    return import('@edeeone/edee-registration/components/PasswordRecoveryForm');
}, {
    ssr: false,
});
let closeModalTimer = null;
const CLOSE_MODAL_AFTER_SUCCESSFUL_MUTATION_MS_DELAY = 1400;
const UserModalBase = function ({ styles, privacyPolicyDisclaimerUserServer, privacyPolicyDisclaimerCompanyServer, }) {
    const finalStyles = userModalClassNames(styles);
    const { locale, url, pathname } = useLocation();
    const { push: navigateTo } = useRouter();
    const searchParams = useSearchParams();
    const variantParam = CONFIG.evita?.staticSearchParameter
        ? {
            [CONFIG.evita.staticSearchParameter]: searchParams.get(CONFIG.evita.staticSearchParameter),
        }
        : {};
    const { t } = useI18n('UserModal');
    const parameterValue = searchParams.get(USER_MODAL_PARAMETER_NAME);
    const isOpen = parameterValue != null;
    const current = typeof searchParams.get(USER_MODAL_PARAMETER_NAME) === 'string'
        ? parseInt(searchParams.get(USER_MODAL_PARAMETER_NAME)?.toString() || '') - 1
        : undefined;
    const [showTabs, setShowTabs] = useState(true);
    function closeModal() {
        closeModalTimer = null;
        window.history.pushState({}, '', getHref(url, { [USER_MODAL_PARAMETER_NAME]: undefined }));
    }
    function hideModalTabs() {
        setShowTabs(false);
    }
    function onLoginSuccess() {
        if (CONFIG.componentSettings?.UserModal?.afterLoginRedirects?.[locale]?.[pathname]) {
            navigateTo(CONFIG.componentSettings?.UserModal?.afterLoginRedirects?.[locale]?.[pathname]);
        }
        else {
            closeModalAfterDelay();
        }
    }
    function closeModalAfterDelay() {
        closeModalTimer = setTimeout(() => {
            closeModal();
        }, CLOSE_MODAL_AFTER_SUCCESSFUL_MUTATION_MS_DELAY);
    }
    useEffect(() => {
        const modalElement = document.getElementById(dialogPanelId);
        if (modalElement) {
            modalElement.scrollTop = 0;
        }
    }, [parameterValue]);
    useEffect(() => {
        closeModalTimer = null;
    }, [isOpen]);
    useEffect(() => {
        setShowTabs(true);
    }, [isOpen, setShowTabs]);
    return (_jsxs(Modal, { isOpen: isOpen, setIsOpen: closeModal, children: [typeof current === 'number' && current < 2 && showTabs && (_jsx(Tabs, { design: "secondary", active: current, styles: { container__custom: finalStyles.tabs }, isFullWidth: true, shallow: true, blocks: [
                    {
                        name: t('signIn'),
                        // name: 'sign',
                        url: getHref(url, {
                            ...variantParam,
                            [USER_MODAL_PARAMETER_NAME]: 1,
                        }),
                    },
                    {
                        name: t('registration'),
                        // name: 'login',
                        url: getHref(url, {
                            ...variantParam,
                            [USER_MODAL_PARAMETER_NAME]: 2,
                        }),
                    },
                ] })), parameterValue === '1' && (_jsx(LoginForm, { inModal: true, onComplete: onLoginSuccess })), parameterValue === '2' && (_jsx(RegisterFormSwitch, { inModal: true, hideModalTabs: hideModalTabs, privacyPolicyDisclaimerUserServer: privacyPolicyDisclaimerUserServer, privacyPolicyDisclaimerCompanyServer: privacyPolicyDisclaimerCompanyServer })), parameterValue === '3' && (_jsx(PasswordRecoveryForm, { inModal: true, hideModalTabs: hideModalTabs }))] }));
};
export const UserModal = (props) => {
    // https://nextjs.org/docs/messages/deopted-into-client-rendering
    return (_jsx(Suspense, { children: _jsx(UserModalBase, { ...props }) }));
};
