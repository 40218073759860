'use client';
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useUser } from '@edeeone/edee-registration/components/useUser';
import { MegamenuItem } from '@edeeone/juan-core/components/megamenu/MegamenuItem';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useUserProfileCredit } from '@edeeone/shop-credit/hooks/useUserProfileCredit';
import { HeaderLogin } from '@edeeone/shop-user/components/headerLogin/HeaderLogin';
import { menuRegistrationClassNames } from '@edeeone/shop-user/components/menuRegistration/MenuRegistration.styles';
import { ProfileMenu } from '@edeeone/shop-user/components/profile/ProfileMenu';
export const MenuRegistration = ({ styles = CONFIG?.componentSettings?.shopUser?.MenuRegistration?.styles, icon = CONFIG?.componentSettings?.shopUser?.MenuRegistration?.icon ??
    'toolUser', badgeIcon = CONFIG?.componentSettings?.shopUser?.MenuRegistration
    ?.badgeIcon ?? 'check', design = CONFIG?.componentSettings?.shopUser?.MenuRegistration?.design ??
    'secondary', profileMenuDesign = CONFIG?.componentSettings?.shopUser?.MenuRegistration
    ?.profileMenuDesign ?? 'secondary', profileMenuUrls, }) => {
    const credit = useUserProfileCredit();
    const { t } = useI18n('MenuRegistration');
    const finalStyles = menuRegistrationClassNames(styles);
    const { user, error } = useUser();
    const badge = user ? (_jsx(SvgSpriteIcon, { icon: badgeIcon, className: finalStyles.icon })) : null;
    return (_jsx(MegamenuItem, { design: design, data: {
            primaryKey: 'edee-registration',
            name: t('label') || 'registration',
            passEmptyUrl: true,
            icon: icon,
            iconBadge: badge,
            children: [
                ...((user?.login &&
                    !error && [
                    {
                        name: t('login') || 'Login',
                        primaryKey: 'edee-registration-login',
                        content: (_jsx("div", { className: finalStyles.userContainer, children: _jsx(ProfileMenu, { design: profileMenuDesign, credit: credit, user: user, companyName: '', showActiveLink: false, showIndexLink: false, profileMenuUrls: profileMenuUrls }) })),
                    },
                ]) ||
                    []),
                ...(((!user?.login || error) && [
                    {
                        name: t('login') || 'Login',
                        primaryKey: 'edee-registration-login',
                        content: (_jsx(_Fragment, { children: _jsx(HeaderLogin, { showHeading: true }) })),
                    },
                ]) ||
                    []),
            ],
        } }));
};
