/**
 * Method to remove diacritics from a string
 * @param value unformatted string
 * @returns resulting string without diacritics included accents
 */
export function removeDiacritics(value) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
//NOTE: I am not sure if this is the best way to extend the String object
//      because it should extends bundle
// declare global {
//   interface String {
//     removeDiacritics(): string;
//   }
// }
// String.prototype.removeDiacritics = function (): string {
//   return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
// };
